import { storeToRefs } from "pinia";
import { useAuthStore } from "@/stores/auth";

export default defineNuxtRouteMiddleware(() => {
  if (process.client) {
    const { loggedIn } = storeToRefs(useAuthStore());
    const route = useRoute();
    console.log("middleware-guest route:", route);
    if (loggedIn) {
      return navigateTo(`/cart`);
    }
  }
});
